import React, { Component } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faChevronUp, faChevronCircleUp, faChevronDown, faChevronCircleDown, faArrowAltCircleUp, faArrowAltCircleDown, faTimes, faUndo, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faFlag as farFlag } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

import Tag from '../components/Tag';
import Loading from '../components/Loading';
import Paging from '../components/Paging';
import NumberInput from '../components/NumberInput';
import ToggleButtonWrapper from './ToggleButtonWrapper';
import Progress from './Progress';
import AddonUpload from './AddonUpload';

import login from '../util/login';
import utils from '../util/utils';

import './Collection.scss';

const DAY = 24 * 60 * 60 * 1000;
const SORTS = {
    'source-asc': (a, b) => utils.collator.compare(a.sortsource, b.sortsource),
    'source-desc': (a, b) => utils.collator.compare(b.sortsource, a.sortsource),
    'profession-asc': (a, b) => utils.collator.compare(a.sortprofession, b.sortprofession),
    'profession-desc': (a, b) => utils.collator.compare(b.sortprofession, a.sortprofession),
    'category-asc': (a, b) => utils.collator.compare(a.sortcategory, b.sortcategory),
    'category-desc': (a, b) => utils.collator.compare(b.sortcategory, a.sortcategory),
    'rarity-asc': (a, b) => (a.rarity || 0) - (b.rarity || 0),
    'rarity-desc': (a, b) => (b.rarity || 0) - (a.rarity || 0),
    'name-asc': (a, b) => utils.collator.compare(a.name, b.name),
    'name-desc': (a, b) => utils.collator.compare(b.name, a.name),
    'id-asc': (a, b) => (a.id || 0) - (b.id || 0),
    'id-desc': (a, b) => (b.id || 0) - (a.id || 0),
    'patch-asc': (a, b) => utils.collator.compare((a.patch || '100'), (b.patch || '100')),
    'patch-desc': (a, b) => utils.collator.compare((b.patch || '100'), (a.patch || '100'))
};

export default class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showZones: false
        };

        localStorage.removeItem('showCategory'); // migration
    }

    toggleRememberFilter = () => {
        if (localStorage.rememberFilters === 'true') {
            localStorage.removeItem('rememberFilters');
        } else {
            localStorage.setItem('rememberFilters', 'true');
        }
        this.setState({ showCategory: Date.now() }); // just to re-render
    }

    toggleCollectedThis = () => {
        this.props.onToggle('collectedThis');
    }

    toggleCollectedAlt = () => {
        this.props.onToggle('collectedAlt');
    }

    toggleNotCollected = () => {
        this.props.onToggle('notCollected');
    }

    toggleCollectedExclusive = () => {
        this.props.onToggle('collectedExclusive');
    }

    toggleObtainable = () => {
        this.props.onToggle('obtainable');
    }

    toggleCollectedUnobtainable = () => {
        this.props.onToggle('collectedUnobtainable');
    }

    toggleUnobtainable = () => {
        this.props.onToggle('unobtainable');
    }

    toggleBugged = () => {
        this.props.onToggle('bugged');
    }

    toggleNeverImplemented = () => {
        this.props.onToggle('neverImplemented');
    }

    toggleTimeLimited = () => {
        this.props.onToggle('timeLimited');
    }

    togglePresent = () => {
        this.props.onToggle('present');
    }

    toggleMissing = () => {
        this.props.onToggle('missing');
    }

    toggleIncluded = () => {
        this.props.onToggle('included');
    }

    toggleExcluded = () => {
        this.props.onToggle('excluded');
    }

    toggleTradable = () => {
        this.props.onToggle('tradable');
    }

    toggleNotTradable = () => {
        this.props.onToggle('notTradable');
    }

    togglePatches = () => {
        this.props.onToggle('patches');
    }

    toggleFactions = () => {
        this.props.onToggle('factions');
    }

    toggleClasses = () => {
        this.props.onToggle('classes');
    }

    toggleRaces = () => {
        this.props.onToggle('races');
    }

    toggleRarities = () => {
        this.props.onToggle('rarities');
    }

    toggleLevels = () => {
        this.props.onToggle('levels');
    }

    toggleAdvanced = () => {
        this.props.onToggle('advanced');
    }

    toggleSources = () => {
        this.props.onToggle('sources');
    }

    toggleBinds = () => {
        this.props.onToggle('binds');
    }

    toggleSlots = () => {
        this.props.onToggle('slots');
    }

    toggleTypes = () => {
        this.props.onToggle('types');
    }

    toggleQualities = () => {
        this.props.onToggle('qualities');
    }

    toggleProfessions = () => {
        this.props.onToggle('professions');
    }

    toggleCategories = () => {
        this.props.onToggle('categories');
    }

    toggleZones = (event) => {
        const showZones = this.state.showZones ? false : event.currentTarget;
        this.props.onToggle('zones').then(() => this.setState({ showZones }));
    }

    toggleSort = () => {
        this.props.onToggle('sort');
    }

    changeRarityMin = (text, number) => {
        this.props.onToggle('rarity', { mintext: text, minnumber: number });
    }

    changeRarityMax = (text, number) => {
        this.props.onToggle('rarity', { maxtext: text, maxnumber: number });
    }

    changeSeenMin = (text, number) => {
        this.props.onToggle('seen', { mintext: text, minnumber: number });
    }

    changeSeenMax = (text, number) => {
        this.props.onToggle('seen', { maxtext: text, maxnumber: number });
    }

    changeLevelMin = (text, number) => {
        this.props.onToggle('level', { mintext: text, minnumber: number });
    }

    changeLevelMax = (text, number) => {
        this.props.onToggle('level', { maxtext: text, maxnumber: number });
    }

    sortSource = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'source-asc' ? 'source-desc' : 'source-asc');
    }

    sortProfession = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'profession-asc' ? 'profession-desc' : 'profession-asc');
    }

    sortCategory = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'category-asc' ? 'category-desc' : 'category-asc');
    }

    sortId = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'id-asc' ? 'id-desc' : 'id-asc');
    }

    sortName = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'name-asc' ? 'name-desc' : 'name-asc');
    }

    sortRarity = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'rarity-desc' ? 'rarity-asc' : 'rarity-desc');
    }

    sortPatch = () => {
        this.props.onToggle('sortby', this.props.collection.sort === 'patch-asc' ? 'patch-desc' : 'patch-asc');
    }

    resetFilters = () => {
        this.props.onToggle('filter', '');
        this.props.onToggle('rarity', { mintext: '', minnumber: undefined, maxtext: '', maxnumber: undefined });
        this.props.onToggle('seen', { mintext: '', minnumber: undefined, maxtext: '', maxnumber: undefined });
        this.props.onToggle('level', { mintext: '', minnumber: undefined, maxtext: '', maxnumber: undefined });

        if (!this.props.collection.showCollectedThis) this.toggleCollectedThis();
        if (!this.props.collection.showCollectedAlt) this.toggleCollectedAlt();
        if (!this.props.collection.showNotCollected) this.toggleNotCollected();

        // regular filters (easy)
        const filters = [ 'faction', 'race', 'class', 'source', 'bind', 'profession', 'category', 'zone', 'patch', 'slot', 'type', 'quality' ];
        filters.forEach(filter => this.props.onToggle(filter, 'All'));

        // advanced filters (cleaner way to do this?)
        if (!this.props.collection.showObtainable) this.toggleObtainable();
        if (!this.props.collection.showTimeLimited) this.toggleTimeLimited();
        if (!this.props.collection.showCollectedUnobtainable) this.toggleCollectedUnobtainable();
        if (!this.props.collection.showUnobtainable) this.toggleUnobtainable();
        if (!this.props.collection.showBugged) this.toggleBugged();
        if (this.props.collection.showNeverImplemented) this.toggleNeverImplemented();
        if (!this.props.collection.showPresent) this.togglePresent();
        if (!this.props.collection.showMissing) this.toggleMissing();
        if (!this.props.collection.showIncluded) this.toggleIncluded();
        if (this.props.collection.showExcluded) this.toggleExcluded();
        if (!this.props.collection.showTradable) this.toggleTradable();
        if (!this.props.collection.showNotTradable) this.toggleNotTradable();
        if (this.props.collection.showCollectedExclusive) this.toggleCollectedExclusive();
    }

    changeFilter = (event) => {
        this.props.onToggle('filter', event.target.value);
    }

    setPage = (start, size) => {
        start = size * ~~(start / size);
        this.props.onToggle('page', start, size);
        utils.scrollTo('collection');
    }

    export = () => {
        const timestamp = moment.utc().format('YYYYMMDD.hhmmss');

        const tpc = this.props.collection;
        const showAppearances = tpc.showAppearances || tpc.showAppearancesources;
        const showSource = tpc.showMounts || tpc.showPets || tpc.showTitles || tpc.showRecipes || tpc.showToys || tpc.showHeirlooms || tpc.showSoulshapes || tpc.showManuscripts;

        const collection = this.sort(this.filter(this.collection()));
        const rows = collection.map(item => [
            // collected status
            item.id,
            item.name,
            item.character ? 'Collected' : (item.account ? 'Collected on Alt' : 'Not Collected'),

            // common info
            utils.unobtainables.find(e => e.key === item.unobtainable)?.value || 'Obtainable',
            item.missing ? 'Missing from API' : 'Present in API',
            item.excluded ? 'Excluded in Ranking' : 'Included in Ranking',
            item.rarity || 0,

            showSource ? ('[' + (item.sourceicon || 'other') + '] ' + (item.source || '') + (item.sourcestanding ? ' (' + item.sourcestanding + ')' : '') + (item.sourcezone ? ' (' + item.sourcezone + ')' : '')) : undefined,

            showAppearances ? (item.slot || 'Other') : undefined,
            showAppearances ? (item.type || 'Other') : undefined
        ]);

        rows.unshift([
            'ID',
            this.label() + ' for ' + tpc?.character1?.key + ' @ ' + timestamp,
            'Collected',

            'Unobtainable',
            'Missing',
            'Excluded',
            'Rarity',

            showSource ? 'Source' : undefined,

            showAppearances ? 'Slot' : undefined,
            showAppearances ? 'Type' : undefined
        ]);

        const csv = rows.map(row => row.filter(e => e !== undefined).map(e => utils.escapeCsv(e)).join(',')).join('\n');
        utils.download(csv, this.label() + '.' + timestamp + '.csv', 'text/csv');
    }

    label = () => {
        if (this.props.collection.showAlts) {
            return 'Alts';
        } else if (this.props.collection.showAchievements) {
            return 'Achievements';
        } else if (this.props.collection.showMounts) {
            return 'Mounts';
        } else if (this.props.collection.showPets) {
            return 'Pets';
        } else if (this.props.collection.showTitles) {
            return 'Titles';
        } else if (this.props.collection.showRecipes) {
            return 'Recipes';
        } else if (this.props.collection.showReputations) {
            return 'Reputations';
        } else if (this.props.collection.showQuests) {
            return 'Quests';
        } else if (this.props.collection.showToys) {
            return 'Toys';
        } else if (this.props.collection.showAppearances) {
            return 'Appearances';
        } else if (this.props.collection.showAppearancesources) {
            return 'Appearance Sources';
        } else if (this.props.collection.showHeirlooms) {
            return 'Heirlooms';
        } else if (this.props.collection.showHunterpets) {
            return 'Hunter Pets';
        } else if (this.props.collection.showSoulshapes) {
            return 'Soulshapes';
        } else if (this.props.collection.showSkips) {
            return 'Skips';
        } else if (this.props.collection.showManuscripts) {
            return 'Manuscripts';
        } else {
            return null;
        }
    }

    collection = () => {
        if (this.props.collection.showAlts) {
            return this.props.collection.alts;
        } else if (this.props.collection.showAchievements) {
            return this.props.collection.achievements;
        } else if (this.props.collection.showMounts) {
            return this.props.collection.mounts;
        } else if (this.props.collection.showPets) {
            return this.props.collection.pets;
        } else if (this.props.collection.showTitles) {
            return this.props.collection.titles;
        } else if (this.props.collection.showRecipes) {
            return this.props.collection.recipes;
        } else if (this.props.collection.showReputations) {
            return this.props.collection.reputations;
        } else if (this.props.collection.showQuests) {
            return this.props.collection.quests;
        } else if (this.props.collection.showToys) {
            return this.props.collection.toys;
        } else if (this.props.collection.showAppearances) {
            return this.props.collection.appearances;
        } else if (this.props.collection.showAppearancesources) {
            return this.props.collection.appearancesources;
        } else if (this.props.collection.showHeirlooms) {
            return this.props.collection.heirlooms;
        } else if (this.props.collection.showHunterpets) {
            return this.props.collection.hunterpets;
        } else if (this.props.collection.showSoulshapes) {
            return this.props.collection.soulshapes;
        } else if (this.props.collection.showSkips) {
            return this.props.collection.skips;
        } else if (this.props.collection.showManuscripts) {
            return this.props.collection.manuscripts;
        } else {
            return null;
        }
    }

    sort = (collection) => {
        if (!collection) return null;
        if (this.props.collection.showAlts) return collection;

        collection.sort(SORTS[this.props.collection.sort] || SORTS['name-asc']);
        return collection;
    }

    filter = (collection) => {
        if (!collection) return null;

        const tpc = this.props.collection;
        const showToggles = !this.props.split && !tpc.showAlts;
        const showCollected = (this.props.showCollected !== false);

        const now = Date.now();

        return collection.filter(item => {
            if (showToggles && showCollected) {
                // character profile
                if (!tpc.showCollectedThis && item.character) return false;
                if (!tpc.showCollectedAlt && !item.character && item.account) return false;
                if (!tpc.showNotCollected && !item.account) return false;

                if (tpc.showCollectedExclusive && item.alt) return false;

                if (!tpc.showObtainable && !item.unobtainable) return false;
                if (!tpc.showCollectedUnobtainable && item.account && item.unobtainable && (item.unobtainable !== 3)) return false;
                if (!tpc.showUnobtainable && !item.account && (item.unobtainable === 1)) return false;
                if (!tpc.showNeverImplemented && !item.account && (item.unobtainable === 2)) return false;
                if (!tpc.showTimeLimited && (item.unobtainable === 3)) return false;
                if (!tpc.showBugged && !item.account && (item.unobtainable === 4)) return false;
            } else if (!tpc.showAlts) {
                // browse or compare
                if (!tpc.showObtainable && !item.unobtainable) return false;
                if (!tpc.showUnobtainable && (item.unobtainable === 1)) return false;
                if (!tpc.showNeverImplemented && (item.unobtainable === 2)) return false;
                if (!tpc.showTimeLimited && (item.unobtainable === 3)) return false;
                if (!tpc.showBugged && (item.unobtainable === 4)) return false;
            }

            // extra filters for certain collection types
            if (tpc.showMounts || tpc.showPets || tpc.showTitles || tpc.showRecipes || tpc.showToys || tpc.showAppearances || tpc.showAppearancesources || tpc.showHeirlooms || tpc.showSoulshapes || tpc.showManuscripts) {
                if (tpc.hideSources.has(item.sourceicon || 'other')) return false;
            }

            if (tpc.showQuests || tpc.showRecipes) {
                if (tpc.hideProfessions.has(item.prof || '')) return false;
            }

            if (tpc.showRecipes || tpc.showAppearances || tpc.showAppearancesources) {
                if (tpc.hideBinds.has(item.bind || 0)) return false;
            }

            if (tpc.showAchievements) {
                if (tpc.hideCategories.has(item.category)) return false;
            }

            if (tpc.showQuests) {
                if (tpc.hideZones.has(item.category || 'Other')) return false;
            }

            if (tpc.showQuests || tpc.showAppearances || tpc.showAppearancesources) {
                if (item.class ? item.class.every(e => tpc.hideClasses.has(e)) : tpc.hideClasses.has('')) return false;
                if (item.race ? item.race.every(e => tpc.hideRaces.has(e)) : tpc.hideRaces.has('')) return false;
            }

            if (tpc.showAppearances || tpc.showAppearancesources) {
                if (tpc.hideSlots.has(item.slot || 'Other')) return false;
                if (tpc.hideTypes.has(item.type || 'Other')) return false;
                if (tpc.hideQualities.has(item.quality || 0)) return false;
            }

            if (tpc.showPets) {
                if (tpc.hideQualities.has(item.quality || 0)) return false;
                if (tpc.level.mintext && (item.level || 1) < tpc.level.minnumber) return false;
                if (tpc.level.maxtext && (item.level || 1) > tpc.level.maxnumber) return false;
                if (!tpc.showTradable && item.tradable) return false;
                if (!tpc.showNotTradable && !item.tradable) return false;
            }

            if (tpc.showHeirlooms) {
                if (tpc.level.mintext && (item.level || 0) < tpc.level.minnumber) return false;
                if (tpc.level.maxtext && (item.level || 0) > tpc.level.maxnumber) return false;
            }

            if (!tpc.showManuscripts && !tpc.showSoulshapes && !tpc.showSkips) {
                if (tpc.hideFactions.has(item.faction)) return false;
            }

            if (!tpc.showAlts) {
                if (tpc.hidePatches.has(item.patch || 'Other')) return false;

                if (!tpc.showPresent && !item.missing) return false;
                if (!tpc.showMissing && item.missing) return false;

                if (!tpc.showIncluded && !item.excluded) return false;
                if (!tpc.showExcluded && item.excluded) return false;

                if (tpc.rarity.mintext && item.rarity < tpc.rarity.minnumber) return false;
                if (tpc.rarity.maxtext && item.rarity > tpc.rarity.maxnumber) return false;

                if (tpc.seen.mintext && (item.seen || 0) < now - DAY * tpc.seen.minnumber) return false;
                if (tpc.seen.maxtext && (item.seen || 0) > now - DAY * tpc.seen.maxnumber) return false;
            }

            if (tpc.regex) {
                return item.fulltext && item.fulltext.match(tpc.regex);
            }

            return true;
        });
    }

    build = (collection) => {
        if (!collection) return null;

        const size = this.props.split ? 'col-12' : 'col-12 col-md-6 col-lg-4 col-xl-3';

        const tpc = this.props.collection;
        const showCollected = (this.props.showCollected !== false) && !tpc.showAlts;
        const showDetails = !tpc.showAlts && !tpc.showAppearances;

        return collection.map(item => <Tag key={item.id} item={item} size={size} showCollected={showCollected} showDetails={showDetails} showRarity={!tpc.showAlts} onCollectedBy={this.props.onCollectedBy} />);
    }

    render() {
        const tpc = this.props.collection;

        let collection = this.sort(this.filter(this.collection()));
        if (!collection) return <Loading message={tpc.message} />;

        const showToggles = !this.props.split && !tpc.showAlts;
        const showProgress = showToggles && (this.props.showProgress !== false);
        const showCollected = (this.props.showCollected !== false);
        const totalSize = collection.length;
        const sort = tpc.sort;

        const pageStart = tpc.start;
        let pageEnd = tpc.start + tpc.pageSize;
        collection = collection.slice(pageStart, pageEnd);
        pageEnd = pageStart + collection.length; // fix size for last page

        const contributor = login.contributor();
        const bulkEditCategory = contributor && !tpc.showAlts && collection?.[0]?.field;
        const bulkEditIds = contributor && collection.map(c => c.id).join('.');

        // only show export for 1) applicable tabs 2) looking at own profile 3) on desktop
        const showExport = !tpc.showAlts && this.props.myself && window.bootstrap.md.matches;

        collection = this.build(collection);

        const rarityInDefaultState = !tpc.rarity?.mintext && !tpc.rarity?.maxtext && !tpc.seen?.mintext && !tpc.seen?.maxtext;
        const levelInDefaultState = !tpc.level?.mintext && !tpc.level?.maxtext;
        const advancedInDefaultState =
            tpc.showObtainable && tpc.showTimeLimited && (!showCollected || tpc.showCollectedUnobtainable) && tpc.showUnobtainable && tpc.showBugged && !tpc.showNeverImplemented // obtainable group
            && tpc.showPresent && tpc.showMissing // present/missing group
            && tpc.showIncluded && !tpc.showExcluded // excluded group
            && (!tpc.showPets || tpc.showTradable) && (!tpc.showPets || tpc.showNotTradable) // tradable group
            && !tpc.showCollectedExclusive; // exclusive mode

        return (
            <div>
                {!showProgress ? null : <Progress label={this.label()} collection={this.collection()} />}

                {showProgress && (tpc.showManuscripts || tpc.showAppearancesources) ? <AddonUpload category={tpc.showManuscripts ? 'manuscripts' : 'appearancesources'} enabled={this.props.myself && window.bootstrap.md.matches} onToggle={this.props.onToggle} /> : null}

                <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                    <div className={'btn-group form-inline mb-3' + (window.bootstrap.md.matches ? '' : ' w-100')}>
                        <input type="text" placeholder="Search" className={'form-control ' + (window.bootstrap.md.matches ? 'mr-3' : 'flex-fill')} value={tpc.filter} onChange={this.changeFilter}></input>
                    </div>
                    {showToggles && showCollected ?
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <button type="button" className={tpc.showCollectedThis ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleCollectedThis}><FontAwesomeIcon icon={tpc.showCollectedThis ? faCheckCircle : faCircle} /> Collected</button>
                            <button type="button" className={tpc.showCollectedAlt ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleCollectedAlt}><FontAwesomeIcon icon={tpc.showCollectedAlt ? faCheckCircle : faCircle} /> Collected on Alt</button>
                            <button type="button" className={tpc.showNotCollected ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleNotCollected}><FontAwesomeIcon icon={tpc.showNotCollected ? faCheckCircle : faCircle} /> Not Collected</button>
                        </div>
                    : null}

                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                        {tpc.showHunterpets || tpc.showManuscripts || tpc.showSoulshapes || tpc.showSkips ? null : <button type="button" className="btn btn-primary" onClick={this.toggleFactions}>Faction <FontAwesomeIcon icon={tpc.hideFactions.size === 0 ? (tpc.showFactions ? faChevronUp : faChevronDown) : (tpc.showFactions ? faChevronCircleUp : faChevronCircleDown)} /></button>}
                        {tpc.showQuests || tpc.showAppearances || tpc.showAppearancesources ? <button type="button" className="btn btn-primary" onClick={this.toggleRaces}>Race <FontAwesomeIcon icon={tpc.hideRaces.size === 0 ? (tpc.showRaces ? faChevronUp : faChevronDown) : (tpc.showRaces ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showQuests || tpc.showAppearances || tpc.showAppearancesources ? <button type="button" className="btn btn-primary" onClick={this.toggleClasses}>Class <FontAwesomeIcon icon={tpc.hideClasses.size === 0 ? (tpc.showClasses ? faChevronUp : faChevronDown) : (tpc.showClasses ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showAlts ? null : <button type="button" className="btn btn-primary" onClick={this.togglePatches}>Expansion <FontAwesomeIcon icon={tpc.hidePatches.size === 0 ? (tpc.showPatches ? faChevronUp : faChevronDown) : (tpc.showPatches ? faChevronCircleUp : faChevronCircleDown)} /></button>}
                        {tpc.showMounts || tpc.showPets || tpc.showTitles || tpc.showRecipes || tpc.showToys || tpc.showAppearances || tpc.showAppearancesources || tpc.showHeirlooms || tpc.showSoulshapes || tpc.showManuscripts ? <button type="button" className="btn btn-primary" onClick={this.toggleSources}>Source <FontAwesomeIcon icon={tpc.hideSources.size === 0 ? (tpc.showSources ? faChevronUp : faChevronDown) : (tpc.showSources ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showAppearances || tpc.showAppearancesources ? <button type="button" className="btn btn-primary" onClick={this.toggleSlots}>Slot <FontAwesomeIcon icon={tpc.hideSlots.size === 0 ? (tpc.showSlots ? faChevronUp : faChevronDown) : (tpc.showSlots ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showAppearances || tpc.showAppearancesources ? <button type="button" className="btn btn-primary" onClick={this.toggleTypes}>Type <FontAwesomeIcon icon={tpc.hideTypes.size === 0 ? (tpc.showTypes ? faChevronUp : faChevronDown) : (tpc.showTypes ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showAppearances || tpc.showAppearancesources || (tpc.showPets && showCollected) ? <button type="button" className="btn btn-primary" onClick={this.toggleQualities}>Quality <FontAwesomeIcon icon={tpc.hideQualities.size === 0 ? (tpc.showQualities ? faChevronUp : faChevronDown) : (tpc.showQualities ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {showCollected && (tpc.showPets || tpc.showHeirlooms) ? <button type="button" className="btn btn-primary" onClick={this.toggleLevels}>Level <FontAwesomeIcon icon={levelInDefaultState ? (tpc.showLevels ? faChevronUp : faChevronDown) : (tpc.showLevels ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showRecipes || tpc.showAppearances || tpc.showAppearancesources ? <button type="button" className="btn btn-primary" onClick={this.toggleBinds}>Binds <FontAwesomeIcon icon={tpc.hideBinds.size === 0 ? (tpc.showBinds ? faChevronUp : faChevronDown) : (tpc.showBinds ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showAchievements ? <button type="button" className="btn btn-primary" onClick={this.toggleCategories}>Category <FontAwesomeIcon icon={tpc.hideCategories.size === 0 ? (tpc.showCategories ? faChevronUp : faChevronDown) : (tpc.showCategories ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showQuests ? <button type="button" className="btn btn-primary" onClick={this.toggleZones}>Category <FontAwesomeIcon icon={tpc.hideZones.size === 0 ? (this.state.showZones ? faChevronUp : faChevronDown) : (this.state.showZones ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showQuests || tpc.showRecipes ? <button type="button" className="btn btn-primary" onClick={this.toggleProfessions}>Profession <FontAwesomeIcon icon={tpc.hideProfessions.size === 0 ? (tpc.showProfessions ? faChevronUp : faChevronDown) : (tpc.showProfessions ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        {tpc.showAlts || tpc.showAppearances || tpc.showAppearancesources || tpc.showHeirlooms || tpc.showManuscripts || tpc.showHunterpets ? null : <button type="button" className="btn btn-primary" onClick={this.toggleRarities}>Rarity <FontAwesomeIcon icon={rarityInDefaultState ? (tpc.showRarity ? faChevronUp : faChevronDown) : (tpc.showRarity ? faChevronCircleUp : faChevronCircleDown)} /></button>}
                        {tpc.showAlts ? null : <button type="button" className="btn btn-primary" onClick={this.toggleAdvanced}>Advanced <FontAwesomeIcon icon={advancedInDefaultState ? (tpc.showAdvanced ? faChevronUp : faChevronDown) : (tpc.showAdvanced ? faChevronCircleUp : faChevronCircleDown)} /></button>}
                    </div>
                    {tpc.showAlts ? null :
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleSort}>Sort <FontAwesomeIcon icon={tpc.showSort ? faChevronUp : faChevronDown} /></button>
                        </div>
                    }

                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                        <Tooltip title="Reset All Filters">
                            <button type="button" className="btn btn-primary" onClick={this.resetFilters}><FontAwesomeIcon icon={faUndo} /></button>
                        </Tooltip>
                    </div>

                    {showExport ?
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <Tooltip title="Download CSV File">
                                <button type="button" className="btn btn-primary" onClick={this.export}><FontAwesomeIcon icon={faFileDownload} /></button>
                            </Tooltip>
                        </div>
                    : null}

                    {bulkEditCategory ?
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <Tooltip title="Bulk Report">
                                <Link to={'/collections/' + bulkEditCategory + '/' + bulkEditIds} target="_blank" className="btn btn-primary"><FontAwesomeIcon icon={farFlag} /></Link>
                            </Tooltip>
                        </div>
                    : null}
                </div>
                {tpc.showFactions ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Faction</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleFactions}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            {tpc.showAlts ? null : <ToggleButtonWrapper id={undefined} label="Neutral" property="faction" checked={!tpc.hideFactions.has(undefined)} onToggle={this.props.onToggle} />}
                            <ToggleButtonWrapper id={0} label="Alliance" property="faction" checked={!tpc.hideFactions.has(0)} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id={1} label="Horde" property="faction" checked={!tpc.hideFactions.has(1)} onToggle={this.props.onToggle} />
                        </div>
                    </div>
                : null}
                {tpc.showRaces ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Race</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleRaces}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="race" variants={utils.races.map(c => c.key)} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="race" variants={utils.races.map(c => c.key)} onToggle={this.props.onToggle} />
                            </div>
                            {utils.races.map(c => <ToggleButtonWrapper key={c.key} id={c.key} label={c.value} property="race" checked={!tpc.hideRaces.has(c.key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showClasses ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Class</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleClasses}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="class" variants={utils.classes.map(c => c.key)} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="class" variants={utils.classes.map(c => c.key)} onToggle={this.props.onToggle} />
                            </div>
                            {utils.classes.map(c => <ToggleButtonWrapper key={c.key} id={c.key} label={c.value} property="class" checked={!tpc.hideClasses.has(c.key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showSources ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Source</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleSources}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="source" variants={utils.sourceicons} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="source" variants={utils.sourceicons} onToggle={this.props.onToggle} />
                            </div>
                            {utils.sourceicons.map(key => <ToggleButtonWrapper key={key} id={key} label={utils.getFriendlySourceName(key)} property="source" checked={!tpc.hideSources.has(key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showBinds ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Binds</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleBinds}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <ToggleButtonWrapper id={0} label="on Pickup" property="bind" checked={!tpc.hideBinds.has(0)} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id={1} label={tpc.showAppearances || tpc.showAppearancesources ? 'on Equip' : 'on Use'} property="bind" checked={!tpc.hideBinds.has(1)} onToggle={this.props.onToggle} />
                        </div>
                    </div>
                : null}
                {tpc.showProfessions ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Profession</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleProfessions}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="profession" variants={[ '', 'Alchemy', 'Archaeology', 'Blacksmithing', 'Cooking', 'Enchanting', 'Engineering',  'Goblin Engineering',  'Gnomish Engineering', 'Fishing', 'Herbalism', 'Inscription', 'Jewelcrafting', 'Leatherworking', 'Mining', 'Skinning', 'Tailoring' ]} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="profession" variants={[ '', 'Alchemy', 'Archaeology', 'Blacksmithing', 'Cooking', 'Enchanting', 'Engineering',  'Goblin Engineering',  'Gnomish Engineering', 'Fishing', 'Herbalism', 'Inscription', 'Jewelcrafting', 'Leatherworking', 'Mining', 'Skinning', 'Tailoring' ]} onToggle={this.props.onToggle} />
                            </div>
                            {tpc.showQuests ? <ToggleButtonWrapper id="" label="Neutral" property="profession" checked={!tpc.hideProfessions.has('')} onToggle={this.props.onToggle} /> : null}
                            <ToggleButtonWrapper id="Alchemy" property="profession" checked={!tpc.hideProfessions.has('Alchemy')} onToggle={this.props.onToggle} />
                            {tpc.showQuests ? <ToggleButtonWrapper id="Archaeology" property="profession" checked={!tpc.hideProfessions.has('Archaeology')} onToggle={this.props.onToggle} /> : null}
                            <ToggleButtonWrapper id="Blacksmithing" property="profession" checked={!tpc.hideProfessions.has('Blacksmithing')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Cooking" property="profession" checked={!tpc.hideProfessions.has('Cooking')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Enchanting" property="profession" checked={!tpc.hideProfessions.has('Enchanting')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Engineering" property="profession" checked={!tpc.hideProfessions.has('Engineering')} variants={[ 'Engineering', 'Goblin Engineering', 'Gnomish Engineering' ]} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Fishing" property="profession" checked={!tpc.hideProfessions.has('Fishing')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Herbalism" property="profession" checked={!tpc.hideProfessions.has('Herbalism')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Inscription" property="profession" checked={!tpc.hideProfessions.has('Inscription')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Jewelcrafting" property="profession" checked={!tpc.hideProfessions.has('Jewelcrafting')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Leatherworking" property="profession" checked={!tpc.hideProfessions.has('Leatherworking')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Mining" property="profession" checked={!tpc.hideProfessions.has('Mining')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Skinning" property="profession" checked={!tpc.hideProfessions.has('Skinning')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Tailoring" property="profession" checked={!tpc.hideProfessions.has('Tailoring')} onToggle={this.props.onToggle} />
                        </div>
                    </div>
                : null}
                {tpc.showCategories ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Category</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleCategories}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="category" variants={tpc.categories} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="category" variants={tpc.categories} onToggle={this.props.onToggle} />
                            </div>
                            {tpc.categories.map(c => <ToggleButtonWrapper key={c} id={c} property="category" checked={!tpc.hideCategories.has(c)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showSlots ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Slot</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleSlots}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="slot" variants={utils.slots} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="slot" variants={utils.slots} onToggle={this.props.onToggle} />
                            </div>
                            {utils.slots.map(c => <ToggleButtonWrapper key={c} id={c} property="slot" checked={!tpc.hideSlots.has(c)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showTypes ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Type</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleTypes}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="type" variants={utils.geartypes} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="type" variants={utils.geartypes} onToggle={this.props.onToggle} />
                            </div>
                            {utils.geartypes.map(c => <ToggleButtonWrapper key={c} id={c} property="type" checked={!tpc.hideTypes.has(c)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showQualities ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Quality</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.toggleQualities}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="quality" variants={utils.qualities.map(c => c.key)} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="quality" variants={utils.qualities.map(c => c.key)} onToggle={this.props.onToggle} />
                            </div>
                            {utils.qualities.filter(c => !tpc.showPets || c.key < 4).map(c => <ToggleButtonWrapper key={c.key} id={c.key} label={c.value} property="quality" checked={!tpc.hideQualities.has(c.key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                <Popper open={this.state.showZones && tpc.showQuests && this.props.split !== 'right'} anchorEl={this.state.showZones} placement="bottom-start">
                    <ClickAwayListener onClickAway={this.toggleZones}>
                        <div className="card mt-1">
                            <div className="btn-group-vertical">
                                <div className="btn-group">
                                    <ToggleButtonWrapper id="All" property="zone" variants={tpc.zones} onToggle={this.props.onToggle} />
                                    <ToggleButtonWrapper id="None" property="zone" variants={tpc.zones} onToggle={this.props.onToggle} />
                                </div>
                                <div className="btn-group-vertical scroller">
                                    {tpc.zones.map(c => <ToggleButtonWrapper key={c} id={c} property="zone" checked={!tpc.hideZones.has(c)} onToggle={this.props.onToggle} />)}
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
                {tpc.showPatches ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Expansion</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={this.togglePatches}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                <ToggleButtonWrapper id="All" property="patch" variants={utils.patches.all} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="patch" variants={utils.patches.all} onToggle={this.props.onToggle} />
                            </div>
                            <ToggleButtonWrapper id="Classic" property="patch" checked={!tpc.hidePatches.has(utils.patches.v1[0])} variants={utils.patches.v1} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="TBC" property="patch" checked={!tpc.hidePatches.has(utils.patches.v2[0])} variants={utils.patches.v2} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="WotLK" property="patch" checked={!tpc.hidePatches.has(utils.patches.v3[0])} variants={utils.patches.v3} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Cataclysm" property="patch" checked={!tpc.hidePatches.has(utils.patches.v4[0])} variants={utils.patches.v4} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="MoP" property="patch" checked={!tpc.hidePatches.has(utils.patches.v5[0])} variants={utils.patches.v5} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="WoD" property="patch" checked={!tpc.hidePatches.has(utils.patches.v6[0])} variants={utils.patches.v6} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Legion" property="patch" checked={!tpc.hidePatches.has(utils.patches.v7[0])} variants={utils.patches.v7} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="BfA" property="patch" checked={!tpc.hidePatches.has(utils.patches.v8[0])} variants={utils.patches.v8} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Shadowlands" property="patch" checked={!tpc.hidePatches.has(utils.patches.v9[0])} variants={utils.patches.v9} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Dragonflight" property="patch" checked={!tpc.hidePatches.has(utils.patches.v10[0])} variants={utils.patches.v10} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="TWW" property="patch" checked={!tpc.hidePatches.has(utils.patches.v11[0])} variants={utils.patches.v11} onToggle={this.props.onToggle} />
                        </div>
                        {/*
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                            {utils.patches.v11.filter(p => !p.includes('x')).map(p => <ToggleButtonWrapper key={p} id={p} property="patch" checked={!tpc.hidePatches.has(p)} onToggle={this.props.onToggle} />)}
                        </div>
                        */}
                    </div>
                : null}
                {tpc.showRarity ?
                    <div className={window.bootstrap.md.matches ? 'd-flex' : ''} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="mb-3">
                            <div className="small mb-1">Rarity</div>
                            <div>
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100 mb-3')  + ' flex-wrap mr-3'}>
                                    <button type="button" className="btn btn-primary" onClick={this.toggleRarities}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                </div>
                                <div className="btn-group mr-3">
                                    <div className="my-auto"><NumberInput placeholder="Min" className="form-control" value={tpc.rarity.mintext} onChange={this.changeRarityMin} /></div>
                                    <div className="my-auto mx-1">-</div>
                                    <div className="my-auto"><NumberInput placeholder="Max" className="form-control" value={tpc.rarity.maxtext} onChange={this.changeRarityMax} /></div>
                                    <div className="my-auto ml-1">%</div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="small mb-1">Seen</div>
                            <div className="btn-group">
                                <div className="my-auto"><NumberInput placeholder="From" className="form-control" value={tpc.seen.maxtext} onChange={this.changeSeenMax} /></div>
                                <div className="my-auto mx-1">-</div>
                                <div className="my-auto"><NumberInput placeholder="To" className="form-control" value={tpc.seen.mintext} onChange={this.changeSeenMin} /></div>
                                <div className="my-auto ml-1">days ago</div>
                            </div>
                        </div>
                    </div>
                : null}
                {tpc.showLevels ?
                    <div className={window.bootstrap.md.matches ? 'd-flex' : ''} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="mb-3">
                            <div className="small mb-1">Level</div>
                            <div>
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100 mb-3')  + ' flex-wrap mr-3'}>
                                    <button type="button" className="btn btn-primary" onClick={this.toggleLevels}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                                </div>
                                <div className="btn-group">
                                    <div className="my-auto"><NumberInput placeholder="From" className="form-control" value={tpc.level.mintext} onChange={this.changeLevelMin} /></div>
                                    <div className="my-auto mx-1">-</div>
                                    <div className="my-auto"><NumberInput placeholder="To" className="form-control" value={tpc.level.maxtext} onChange={this.changeLevelMax} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
                {tpc.showAdvanced ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Advanced</div>
                        <div>
                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                                <button type="button" className="btn btn-primary" onClick={this.toggleAdvanced}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            </div>

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={tpc.showObtainable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleObtainable}><FontAwesomeIcon icon={tpc.showObtainable ? faCheckCircle : faCircle} /> Obtainable</button>
                                <button type="button" className={tpc.showTimeLimited ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleTimeLimited}><FontAwesomeIcon icon={tpc.showTimeLimited ? faCheckCircle : faCircle} /> Obtainable for Limited Time</button>
                                {showToggles && showCollected ? <button type="button" className={tpc.showCollectedUnobtainable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleCollectedUnobtainable}><FontAwesomeIcon icon={tpc.showCollectedUnobtainable ? faCheckCircle : faCircle} /> Unobtainable (Collected)</button> : null}
                                <button type="button" className={tpc.showUnobtainable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleUnobtainable}><FontAwesomeIcon icon={tpc.showUnobtainable ? faCheckCircle : faCircle} /> Unobtainable</button>
                                <button type="button" className={tpc.showBugged ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleBugged}><FontAwesomeIcon icon={tpc.showBugged ? faCheckCircle : faCircle} /> Bugged</button>
                                <button type="button" className={tpc.showNeverImplemented ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleNeverImplemented}><FontAwesomeIcon icon={tpc.showNeverImplemented ? faCheckCircle : faCircle} /> Never Implemented</button>
                            </div>

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={tpc.showPresent ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.togglePresent}><FontAwesomeIcon icon={tpc.showPresent ? faCheckCircle : faCircle} /> Present in API</button>
                                <button type="button" className={tpc.showMissing ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleMissing}><FontAwesomeIcon icon={tpc.showMissing ? faCheckCircle : faCircle} /> Missing from API</button>
                            </div>

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={tpc.showIncluded ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleIncluded}><FontAwesomeIcon icon={tpc.showIncluded ? faCheckCircle : faCircle} /> Included in Ranking</button>
                                <button type="button" className={tpc.showExcluded ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleExcluded}><FontAwesomeIcon icon={tpc.showExcluded ? faCheckCircle : faCircle} /> Excluded from Ranking</button>
                            </div>

                            {tpc.showPets ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    <button type="button" className={tpc.showTradable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleTradable}><FontAwesomeIcon icon={tpc.showTradable ? faCheckCircle : faCircle} /> Tradable</button>
                                    <button type="button" className={tpc.showNotTradable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleNotTradable}><FontAwesomeIcon icon={tpc.showNotTradable ? faCheckCircle : faCircle} /> Not Tradable</button>
                                </div>
                            : null}

                            {showToggles && showCollected ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    <button type="button" className={tpc.showCollectedExclusive ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleCollectedExclusive}><FontAwesomeIcon icon={tpc.showCollectedExclusive ? faCheckCircle : faCircle} /> Exclusive to This Character</button>
                                </div>
                            : null}

                            {!this.props.split && (tpc.showAppearancesources || tpc.showAppearances) ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    {tpc.showAppearancesources ? <Link to="appearances" className="btn btn-primary"><FontAwesomeIcon icon={faCircle} /> Unique Only</Link> : null}
                                    {tpc.showAppearances ? <Link to="appearancesources" className="btn btn-primary active"><FontAwesomeIcon icon={faCheckCircle} /> Unique Only</Link> : null}
                                </div>
                            : null}

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={localStorage.rememberFilters ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleRememberFilter}><FontAwesomeIcon icon={localStorage.rememberFilters ? faCheckCircle : faCircle} /> Remember Filters</button>
                            </div>
                        </div>
                    </div>
                : null}
                {tpc.showSort ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Sort</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleSort}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            {tpc.showSortId && contributor ? <button type="button" className={sort.startsWith('id') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortId}><FontAwesomeIcon icon={sort === 'id-asc' ? faArrowAltCircleUp : (sort === 'id-desc' ? faArrowAltCircleDown : faCircle)} /> ID</button> : null}
                            {tpc.showSortName ? <button type="button" className={sort.startsWith('name') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortName}><FontAwesomeIcon icon={sort === 'name-asc' ? faArrowAltCircleUp : (sort === 'name-desc' ? faArrowAltCircleDown : faCircle)} /> Name</button> : null}
                            {tpc.showSortSource ? <button type="button" className={sort.startsWith('source') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortSource}><FontAwesomeIcon icon={sort === 'source-asc' ? faArrowAltCircleUp : (sort === 'source-desc' ? faArrowAltCircleDown : faCircle)} /> Source</button> : null}
                            {tpc.showSortProfession ? <button type="button" className={sort.startsWith('profession') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortProfession}><FontAwesomeIcon icon={sort === 'profession-asc' ? faArrowAltCircleUp : (sort === 'profession-desc' ? faArrowAltCircleDown : faCircle)} /> Profession</button> : null}
                            {tpc.showSortCategory ? <button type="button" className={sort.startsWith('category') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortCategory}><FontAwesomeIcon icon={sort === 'category-asc' ? faArrowAltCircleUp : (sort === 'category-desc' ? faArrowAltCircleDown : faCircle)} /> Category</button> : null}
                            {tpc.showSortRarity ? <button type="button" className={sort.startsWith('rarity') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortRarity}><FontAwesomeIcon icon={sort === 'rarity-asc' ? faArrowAltCircleUp : (sort === 'rarity-desc' ? faArrowAltCircleDown : faCircle)} /> Rarity</button> : null}
                            <button type="button" className={sort.startsWith('patch') ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.sortPatch}><FontAwesomeIcon icon={sort === 'patch-asc' ? faArrowAltCircleUp : (sort === 'patch-desc' ? faArrowAltCircleDown : faCircle)} /> Patch</button>
                        </div>
                    </div>
                : null}
                <Loading message={tpc.message} />
                <div id="collection" className="row m-0 collection">
                    {collection.length > 0 ? collection : 'No matches found'}
                </div>
                {collection.length === 0 ? null : 
                    <div className="mt-3" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <Paging start={pageStart} end={pageEnd} pageSize={tpc.pageSize} pageSizes={[ 40, 60, 120, 180 ]} totalSize={totalSize} onClick={this.setPage} />
                    </div>
                }
            </div>
        );
    }
}