import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faAward, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faTwitch, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';

import SubregionLookup from '../autocomplete/SubregionLookup';
import RealmLookup from '../autocomplete/RealmLookup';
import Loading from '../components/Loading';
import Paging from '../components/Paging';
import Section from '../components/Section';

import utils from '../util/utils';
import api from '../util/api';

import './Leaderboard.scss';

// TODO merge this into utils.LEADERBOARDS somehow
const LEADERBOARDS = [
    {
        title: 'Account-Wide Leaderboards',
        categories: [ 'completion-score', 'achievement-points', 'account-mounts', 'pets-score', 'account-titles', 'account-reputations', 'account-recipes', 'account-quests', 'account-toys', 'account-appearance-sources' ],
        toggleMoreAccountLeaderboards: true
    },
    {
        title: null,
        categories: [ 'completion-count', 'achievements', 'feats', 'legacy', 'pets', 'alts', 'alts-score', 'honorlevel', 'account-kills' ]
    },
    {
        title: 'Recipes by Profession',
        categories: [ 'account-recipes-alchemy', 'account-recipes-blacksmithing', 'account-recipes-cooking', 'account-recipes-enchanting', 'account-recipes-engineering', 'account-recipes-inscription', 'account-recipes-jewelcrafting', 'account-recipes-leatherworking', 'account-recipes-tailoring' ],
        submenu: 'account-recipes'
    },
    {
        title: 'Character-Specific Leaderboards',
        categories: [ 'character-mounts', 'character-titles', 'character-reputations', 'character-recipes', 'character-quests', 'character-kills' ]
    },
    {
        title: 'War Within Races',
        categories: [ 'race-glory-nerubar', 'race-glory-delver', 'race-challenge-tww', 'race-level80' ],
        toggleOlderRaces: true
    },
    {
        title: 'Dragonflight Races',
        categories: [ 'race-glory-dream', 'race-glory-aberrus', 'race-glory-vault', 'race-challenge-dragonflight', 'race-hero-dragonflight', 'race-level70' ]
    },
    {
        title: 'Shadowlands Races',
        categories: [ 'race-glory-sepulcher', 'race-glory-dominant', 'race-glory-nathria', 'race-challenge-shadowlands', 'race-hero-shadowlands', 'race-level60' ]
    },
    {
        title: 'Battle for Azeroth Races',
        categories: [ 'race-glory-nyalotha', 'race-glory-eternal', 'race-glory-dazaralor', 'race-glory-uldir', 'race-challenge-bfa', 'race-hero-bfa', 'race-level120-legacy' ]
    },
    {
        title: 'Legion Races',
        categories: [ 'race-glory-argus', 'race-glory-tomb', 'race-glory-legion', 'race-challenge-legion', 'race-hero-legion', 'race-level110-legacy' ]
    },
    {
        title: 'Warlords of Draenor Races',
        categories: [ 'race-glory-hellfire', 'race-glory-draenor', 'race-challenge-draenor', 'race-hero-draenor', 'race-level100-legacy' ]
    },
    {
        title: 'Mists of Pandaria Races',
        categories: [ 'race-glory-orgrimmar', 'race-glory-thundering', 'race-glory-pandaria', 'race-challenge-pandaria', 'race-hero-pandaria', 'race-level90-legacy' ]
    },
    {
        title: 'Cataclysm Races',
        categories: [ 'race-glory-dragonsoul', 'race-glory-firelands', 'race-glory-cataclysm', 'race-hero-cataclysm', 'race-level85-legacy' ]
    },
    {
        title: 'Wrath of the Lich King Races',
        categories: [ 'race-glory-icecrown', 'race-glory-ulduar', 'race-glory-raider', 'race-hero-wrath', 'race-level80-legacy' ]
    }
];

const OBTAINABLE = new Set([
    'completion-score', 'completion-count',
    'account-mounts', 'pets', 'pets-score', 'account-titles', 'account-reputations', 'account-recipes', 'account-quests', 'account-toys', 'account-appearances',
    'account-recipes-alchemy', 'account-recipes-blacksmithing', 'account-recipes-cooking', 'account-recipes-enchanting', 'account-recipes-engineering', 'account-recipes-inscription', 'account-recipes-jewelcrafting', 'account-recipes-leatherworking', 'account-recipes-tailoring',
    'character-mounts', 'character-titles', 'character-reputations', 'character-recipes', 'character-quests'
]);

class Leaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            max: null,
            message: null,
            leaderboard: null,
            start: 0,
            showRegionFilters: false,
            showMoreAccountLeaderboards: false,
            showOlderRaces: false
        };
        this.pageSize = 25;
    }
    
    componentDidMount() {
        this.setState({ message: 'Loading...' });
        this.lookup();
        this.lookupMax();
    }

    componentDidUpdate(previous) {
        if (this.props.category === previous.category && this.props.region === previous.region && this.props.realm === previous.realm && this.props.guild === previous.guild && this.props.rank === previous.rank) return;
        this.lookup();
    }

    setPage = (start) => {
        utils.scrollTo('leaderboard');
        this.props.history.push(this.props.history.location.pathname + '?rank=' + (start + 1));
    }

    toggleRegionFilters = () => {
        this.setState({ showRegionFilters: !this.state.showRegionFilters });
    }

    toggleMoreAccountLeaderboards = () => {
        this.setState({ showMoreAccountLeaderboards: !this.state.showMoreAccountLeaderboards });
    }

    toggleOlderRaces = () => {
        this.setState({ showOlderRaces: !this.state.showOlderRaces });
    }

    lookupMax = async () => {
        const max = await api.web('max');
        this.setState({ max });
    }

    lookup = async () => {
        const { category, realm, region, guild } = this.props;

        let start = 0;
        if (!guild) {
            try {
                start = (parseInt(this.props.rank) - 1) || 0;
                start = this.pageSize * Math.trunc(start / this.pageSize);
            } catch (e) {

            }
        }

        if (start > utils.MAXPAGE) {
            // for now we cap the leaderboards for performance reasons
            this.props.history.push(this.props.history.location.pathname);
            //this.props.history.push(this.props.history.location.pathname + '?rank=' + (utils.MAXPAGE - 1));
            return;
        }

        this.setState({ message: 'Loading...' });
        try {
            const response = await api.get('leaderboards/' + encodeURIComponent(category) + (region ? '/' + encodeURIComponent(region) : '') + (realm ? '/' + encodeURIComponent(realm) : '') + (guild ? '/' + encodeURIComponent(guild) : '') + '?offset=' + start);
            if (response.status === 404) {
                // probably an old URL
                this.props.history.push('/leaderboards/completion-score');
                return;
            } else if (response.status !== 200) {
                this.setState({ message: 'Error!', leaderboard: null });
                return;
            }

            const json = await response.json();
            if (json.message) {
                this.setState({ message: json.message });
            } else if (json.errorMessage) {
                // should no longer happen?
                this.setState({ message: 'Error!', leaderboard: null });
            } else {
                // auto-close filters on mobile
                if (!window.bootstrap.md.matches) this.setState({ showRegionFilters: false });
                this.setState({ message: null, leaderboard: json, start: start });
            }
        } catch (e) {
            this.setState({ message: 'Error!', leaderboard: null });
        }
    }

    formatScore = (row) => {
        if (row.timestamp) {
            const utc = moment.utc(row.timestamp);
            const local = moment.utc(row.timestamp).local();
            const tooltip = <><div>{local.format('ll')} {local.format('LTS')} Local Time</div><div>{utc.format('ll')} {utc.format('LTS')} UTC</div></>;

            const meta = this.state.leaderboard?.meta;
            if (meta && row.progress < meta) {
                // format as progress
                return <Tooltip title={tooltip}><div>{row.progress + '/' + meta}</div></Tooltip>;
            } else {
                // format as date
                const result = <div><div className={window.bootstrap.md.matches ? 'd-inline-block' : ''}>{local.format('ll')}</div> <div className={window.bootstrap.md.matches ? 'd-inline-block' : 'small'}>{local.format('LTS')}</div></div>;
                return <Tooltip title={tooltip}>{result}</Tooltip>;
            }
        } else if (row.score) {
            // format as score
            const result = utils.formatScore(this.props.category, row.score);
            const percent = utils.formatPercentAndScore(this.props.category, row.score, this.state.max);

            if (percent) {
                return <Tooltip title={percent}><span>{result}</span></Tooltip>;
            } else {
                return result;
            }
        }

        return null;
    }

    link = () => {
        let link = '';
        if (this.props.region) {
            link += '/' + this.props.region;
            if (this.props.realm) {
                link += '/' + this.props.realm;
                if (this.props.guild) {
                    link += '/' + this.props.guild;
                }
            }
        }
        return link;
    }

    title = () => {
        let title = 'World';
        if (this.props.region === 'custom') {
            title = 'Custom';
        } else if (this.props.region === 'subregion') {
            title = this.props.realm;
        } else if (this.props.guild) {
            title = 'Guild';
        } else if (this.props.region) {
            title = this.props.region?.toUpperCase();
            if (this.state.leaderboard?.realm) {
                title += '-' + this.state.leaderboard?.realm;
            }
        }
        title += ' Leaderboards';
        return title;
    }

    subtitle = () => {
        let subtitle = utils.getFriendlyCategoryName(this.props.category, true);
        if (this.props.guild && this.props.region !== 'custom') {
            subtitle = 
                <>
                    <div>❮{this.props.guild}❯ {this.props.region?.toUpperCase()}-{this.state.leaderboard?.realm}</div>
                    <div>{subtitle}</div>
                </>;
        }
        return subtitle;
    }

    navigation = () => {
        if (!this.state.leaderboard) return null;

        const link = this.link();
        const title = this.title();
        const subtitle = this.subtitle();
        document.title = (this.state.leaderboard?.guild ? (this.state.leaderboard.guild + ' @ ' + this.state.leaderboard.region + '-' + this.state.leaderboard.realm + ' | ') : '') + title + ' | ' + subtitle + ' | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        // decide which buttons to show
        let groups = this.state.showOlderRaces ? LEADERBOARDS.slice(0) : LEADERBOARDS.slice(0, 5);
        if (!this.state.showMoreAccountLeaderboards) groups.splice(1, 1);
        groups = groups.filter(group => !group.submenu || this.props.category?.startsWith(group.submenu));

        const obtainableShow = OBTAINABLE.has(this.props.category?.replace('-obtainable', ''));
        const obtainableActive = this.props.category?.endsWith('-obtainable');
        const obtainableLink = '/leaderboards/' + encodeURIComponent(this.props.category?.replace('-obtainable', '')) + (obtainableActive ? '' : '-obtainable') + link;

        if (obtainableShow) {
            localStorage.setItem('leaderboards.showObtainable', obtainableActive ? 'true' : 'false');
        }
        const obtainableNav = localStorage.getItem('leaderboards.showObtainable') === 'true';

        const uniqueShow = this.props.category?.startsWith('account-appearance');
        const uniqueActive = this.props.category?.startsWith('account-appearances');
        const uniqueLink = '/leaderboards/' + (uniqueActive ? 'account-appearance-sources' : 'account-appearances') + link;

        return (
            <Section title={title} subtitle={subtitle} intro={utils.getDescription(this.props.category)}>
                {window.bootstrap.md.matches ? null : <div className={'text-center w-100' + (this.state.showRegionFilters ? ' mb-3' : '')}><button type="button" className="btn btn-primary" onClick={this.toggleRegionFilters}>Change <FontAwesomeIcon icon={this.state.showRegionFilters ? faChevronUp : faChevronDown} /></button></div>}
                {this.state.showRegionFilters || window.bootstrap.md.matches ?
                    <>
                    <div className="row">
                        <div className={window.bootstrap.md.matches ? 'col-auto' : 'col-12 mt-3'}>
                            {window.bootstrap.md.matches ? <div className="small mb-1">Region</div> : null}
                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : ' w-100')}>
                                <NavLink to={'/leaderboards/' + encodeURIComponent(this.props.category)} exact className={'btn btn-primary' + (window.bootstrap.md.matches ? '' : ' col')}>World</NavLink>
                                {[ 'eu', 'kr', 'tw', 'us' ].map(region => <NavLink key={region} to={'/leaderboards/' + encodeURIComponent(this.props.category) + '/' + region} exact className={'btn btn-primary' + (window.bootstrap.md.matches ? '' : ' col')}>{region.toUpperCase()}</NavLink>)}
                            </div>
                        </div>
                        <div className={window.bootstrap.md.matches ? 'col-auto' : 'col-12 mt-3'}>
                            {window.bootstrap.md.matches ? <div className="small mb-1">Subregion</div> : null}
                            <div className={window.bootstrap.md.matches ? '' : 'w-100'}>
                                <SubregionLookup history={this.props.history} category={this.props.category} />
                            </div>
                        </div>
                        <div className={window.bootstrap.md.matches ? 'col-auto' : 'col-12 mt-3'}>
                            {window.bootstrap.md.matches ? <div className="small mb-1">Realm</div> : null}
                            <div className={window.bootstrap.md.matches ? '' : 'w-100'}>
                                <RealmLookup history={this.props.history} category={this.props.category} />
                            </div>
                        </div>
                        {obtainableShow || uniqueShow ?
                            <div className={window.bootstrap.md.matches ? 'col-auto' : 'col-12 mt-3'}>
                                {window.bootstrap.md.matches ? <div className="small mb-1">Options</div> : null}
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : ' w-100')}>
                                    {uniqueShow ? <Link to={uniqueLink} className={'btn btn-primary' + (uniqueActive ? ' active' : '') + (window.bootstrap.md.matches ? '' : ' col')}><FontAwesomeIcon icon={uniqueActive ? faCheckCircle : faCircle} /> Unique Only</Link> : null}
                                    {obtainableShow ? <Link to={obtainableLink} className={'btn btn-primary' + (obtainableActive ? ' active' : '') + (window.bootstrap.md.matches ? '' : ' col')}><FontAwesomeIcon icon={obtainableActive ? faCheckCircle : faCircle} /> Obtainable Only</Link> : null}
                                </div>
                            </div>
                        : null}
                    </div>
                    {groups.map(group =>
                        <div className="mt-3" key={group.title}>
                            {group.title ? <div className="small mb-1">{group.title}</div> : null}
                            <div className={'btn-group' + (window.bootstrap.md.matches ? ' flex-wrap' : '-vertical w-100')}>
                                {group.categories.map(category => <NavLink to={'/leaderboards/' + category + (obtainableNav && OBTAINABLE.has(category) ? '-obtainable' : '') + link} className="btn btn-primary" isActive={match => match || (uniqueActive && category === 'account-appearance-sources')} key={category}>{category === 'account-appearance-sources' ? 'Appearances' : utils.getFriendlyCategoryName(category)}</NavLink>)}
                                {group.toggleMoreAccountLeaderboards ? <button className="btn btn-primary" onClick={this.toggleMoreAccountLeaderboards}>{this.state.showMoreAccountLeaderboards ? 'Less' : 'More'} <FontAwesomeIcon icon={this.state.showMoreAccountLeaderboards ? faChevronUp : faChevronDown} /></button> : null}
                                {group.toggleOlderRaces ? <button className="btn btn-primary" onClick={this.toggleOlderRaces}>{this.state.showOlderRaces ? 'Less' : 'More'} <FontAwesomeIcon icon={this.state.showOlderRaces ? faChevronUp : faChevronDown} /></button> : null}
                            </div>
                        </div>
                    )}
                    </>
                : null}
            </Section>
        );
    }

    table = () => {
        if (!this.state.leaderboard) return null;

        const rows = [];
        const results = this.state.leaderboard.results;
        const offset = this.state.start + 1;
        const hideRealm = false;
        const hideGuild = this.props.guild;
        const scoreDate = this.props.category.startsWith('race-');

        for (let i = 0; i < results.length; i++) {
            const tied = results[i].rank !== (offset + i);

            rows.push(
                <tr key={'row' + i}>
                    <td className={'text-nowrap align-middle text-right ' + (tied ? 'tied' : '')}>{utils.formatScore(null, results[i].rank)}</td>
                    <td className="align-middle p-0">
                        {results[i].thumbnail ? <img className="rounded" style={{width:'2em',height:'2em'}} src={'https://render.worldofwarcraft.com/' + results[i].region.toLowerCase() + '/character/' + results[i].thumbnail} alt="Avatar" /> : null}
                    </td>
                    <td className="align-middle py-1">
                        <div>
                            <Link className={'text-break class-' + results[i].class} to={'/characters/' + encodeURIComponent(results[i].region) + '/' + encodeURIComponent(results[i].realm) + '/' + encodeURIComponent(results[i].character)}>{results[i].character}</Link>
                            {results[i].thanks ? <Tooltip title={'Thank you for the support, ' + results[i].character + '!'}><a href="https://www.patreon.com/shoogen" target="external" className="ml-2"><FontAwesomeIcon icon={faAward} /></a></Tooltip> : null}
                            {results[i].twitch ? <a href={'https://twitch.tv/' + encodeURIComponent(results[i].twitch)} target="external" className="ml-2"><FontAwesomeIcon icon={faTwitch} /></a> : null}
                            {results[i].youtube ? <a href={'https://youtube.com/' + results[i].youtube} target="external" className="ml-2"><FontAwesomeIcon icon={faYoutube} /></a> : null}
                            {results[i].twitter ? <a href={'https://twitter.com/' + encodeURIComponent(results[i].twitter)} target="external" className="ml-2"><FontAwesomeIcon icon={faTwitter} /></a> : null}
                        </div>
                        {hideGuild || !results[i].guildName || window.bootstrap.md.matches ? null : <div className="small mt-1"><Link className="text-break" to={'/leaderboards/' + encodeURIComponent(this.props.category) + '/' + encodeURIComponent(results[i].region) + '/' + encodeURIComponent(results[i].guildRealm) + '/' + encodeURIComponent(results[i].guildName)}>❮{results[i].guildName}❯</Link></div>}
                        {hideRealm || window.bootstrap.md.matches ? null : <div className="small mt-1"><Link to={'/leaderboards/' + encodeURIComponent(this.props.category) + '/' + encodeURIComponent(utils.clean(results[i].region)) + '/' + (this.state.leaderboard.realm ? '' : 'connected-') + encodeURIComponent(utils.slug(results[i].realm))}>{results[i].region}-{results[i].realm}</Link></div>}
                    </td>
                    {hideGuild || !window.bootstrap.md.matches ? null : <td><Link className="text-break" to={'/leaderboards/' + encodeURIComponent(this.props.category) + '/' + encodeURIComponent(results[i].region) + '/' + encodeURIComponent(results[i].guildRealm) + '/' + encodeURIComponent(results[i].guildName)}>{results[i].guildName}</Link></td>}
                    {hideRealm || !window.bootstrap.md.matches ? null : <td><Link to={'/leaderboards/' + encodeURIComponent(this.props.category) + '/' + encodeURIComponent(utils.clean(results[i].region)) + '/' + (this.state.leaderboard.realm ? '' : 'connected-') + encodeURIComponent(utils.slug(results[i].realm))}>{results[i].region}-{results[i].realm}</Link></td>}
                    <td className={'text-nowrap align-middle' + (window.bootstrap.md.matches ? '' : ' text-right')}>{this.formatScore(results[i])}</td>
                </tr>
            );
        }

        if (rows.length === 0) {
            rows.push(
                <tr key="row0">
                    <td colSpan="6">{this.state.start === 0 ? 'There are no known characters on this leaderboard.' : 'There are no results on this page.'}</td>
                </tr>
            );
        }

        let footer = null;
        if (!hideGuild && this.props.region !== 'custom') {
            footer = (
                <tfoot>
                    <tr>
                        <td colSpan="6">
                            <Paging start={this.state.start} end={this.state.start + this.pageSize} pageSize={this.pageSize} onClick={this.setPage} />
                        </td>
                    </tr>
                </tfoot>
            );
        }

        const cols = 4 - (hideGuild ? 1 : 0) - (hideRealm ? 1 : 0);
        const width = window.bootstrap.md.matches ? (96 / cols) + '%' : '94%';

        return (
            <div id="leaderboard" className="m-3 card">
                <table className="table table-hover m-0">
                    <thead className="thead-dark">
                        <tr>
                            <th width="2%" className="text-right">#</th>
                            <th width="2%">&nbsp;</th>
                            <th width={width}>Character</th>
                            {hideGuild || !window.bootstrap.md.matches ? null : <th width={width}>Guild</th>}
                            {hideRealm || !window.bootstrap.md.matches ? null : <th width={width}>Realm</th>}
                            <th width={window.bootstrap.md.matches ? width : '2%'} className={'text-nowrap' + (window.bootstrap.md.matches ? '' : ' text-right')}>{window.bootstrap.md.matches ? utils.getFriendlyCategoryName(this.props.category) : (scoreDate ? 'Date' : 'Score')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                    {footer}
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.message ? <Loading message={this.state.message} /> : null}
                {this.navigation()}
                {this.table()}
            </div>
        );
    }
}

export default Leaderboard;
