import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

import CharacterLookup from '../autocomplete/CharacterLookup';
import LoginButton from './LoginButton';
import Toast from '../components/Toast';

import logo from '../images/logo.png';

ReactGA4.initialize('G-KZLRH85EYW');

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            backUrl: '/'
        };
    }

    componentDidMount() {
        this.action(this.props.history.location);
        this.unlisten = this.props.history.listen((location, action) => {
            this.action(location);
            ReactGA4.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    action(location) {
        if (location.pathname.startsWith('/leaderboards')) {
            this.setState({ showSearch: false, backUrl: location.pathname });
        } else {
            this.setState({ showSearch: false });
        }
    }

    toggleSearch = () => {
        this.setState({ 'showSearch': !this.state.showSearch });
    }

    render() {
        return (
            <>
                <div className="d-flex header bar">
                    {this.state.showSearch && !window.bootstrap.md.matches ? null : <Link to="/"><img src={logo} alt="Data for Azeroth Logo" width="250" height="50" /></Link>}
                    {!this.state.showSearch && !window.bootstrap.md.matches ? null :
                        <>
                            <div className={'my-auto ' + (window.bootstrap.md.matches ? 'ml-auto mr-1' : 'ml-1 flex-fill')}>
                                <CharacterLookup showCompare={this.state.showCompare} history={this.props.history} />
                            </div>
                            <div className={'my-auto ' + (window.bootstrap.md.matches ? 'mr-1' : 'ml-1')}>
                                <LoginButton history={this.props.history} />
                            </div>
                        </>
                    }
                    {window.bootstrap.md.matches ? null : (
                        this.state.showSearch ?
                        <div className="my-auto m-1">
                            <button type="button" className="btn btn-primary" onClick={this.toggleSearch}><FontAwesomeIcon icon={faTimes} /></button>
                        </div>
                        :
                        <div className="my-auto m-1 ml-auto">
                            <button type="button" className="btn btn-primary" onClick={this.toggleSearch}><FontAwesomeIcon icon={faBars} /></button>
                        </div>
                    )}
                </div>
                <Toast show={false}>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> I will be performing database maintenance over the next few days.
                    Character refreshes are currently re-enabled but you may experience slowness or other errors. Thank you for your patience!
                    <div className="mt-3"><a href="https://discord.gg/dataforazeroth" target="external"><FontAwesomeIcon icon={faDiscord} /> Come join our Discord!</a></div>
                </Toast>
                <Toast show={true}>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Aug 26 &mdash; Sep 26: Support <i>Data for Azeroth</i> on <a href="https://twitch.tv/shoogen" target="external">Twitch</a> and get a Watcher of the Huntress pet! <Link to="/twitch-drops">Click here for details</Link>
                </Toast>
            </>
        );
    }
}

export default Header;
